import React from "react"
import { FaStar } from "react-icons/fa"
import Button from "../components/button"
import { Flip, Fade } from "react-awesome-reveal"

function Block({
  content = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur, eum. Sed doloribus facilis ratione consequuntur.",
  author = "Jane, Chair Massage",
  service = "",
}) {
  return (
    <div className="text-blue-900">
      <div className="flex items-center space-x-2 justify-center mb-3 text-2xl text-orange-200">
        <FaStar />
        <FaStar />
        <FaStar />
        <FaStar />
        <FaStar />
      </div>
      <p className="leading-loose">{content}</p>
      <p className="font-bold text-primary mt-3">
        {author}
        {service ? ` , ${service}` : ""}
      </p>
    </div>
  )
}

export default function TestimonialsHome({ data = {} }) {
  const { title, subtitle, list, cta } = data
  return (
    <div className="container mx-auto text-center md:mb-24 mb-10">
      <h3 className="text-5xl text-primary font-serif">{title}</h3>
      <p className="text-blue-900">{subtitle}</p>
      <div className="grid md:grid-cols-3 gap-8 my-12">
        {list.map(({ content, author, service }, index) => (
          <Block
            key={`home-testimonials-${index}`}
            content={content}
            author={author}
            service={service}
          />
        ))}
      </div>
      <Button to={cta.url}>{cta.text}</Button>
    </div>
  )
}
