import React from "react"
import Button from "../components/button"
import { Link } from "gatsby"
import { IoIosArrowRoundForward as Arrow } from "react-icons/io"
import HeroSvg from "./heroSvg"
import video from "../videos/video.mp4"
import HomeHero from "../components/imagesComponent/homeHero"
import "./style.css"

function HeroVideo() {
  return (
    <div
      className="absolute top-0 right-0 lg:block hidden rounded-tl-lg overflow-hidden mt-24"
      style={{ height: "85%", width: "45%" }}
    >
      <video
        className="absolute top-0 left-0 h-full w-full object-cover"
        width="100%"
        height="100%"
        autoPlay={true}
        muted={true}
        loop={true}
        playsInline={true}
        poster={<HomeHero />}
      >
        <source src={video} type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>
    </div>
  )
}

export default function Hero({ data = {} }) {
  const { title, content, cta } = data
  return (
    <>
      <section className="hero-bg bg-orange-100 pt-32 pb-24 relative overflow-hidden">
        <div className="grid lg:grid-cols-2 gap-10 container mx-auto px-5">
          <div className="text-primary pb-12 pt-20 relative">
            <Link
              to="/reviews"
              className="bg-orange-200 inline-flex items-center pl-2 pr-4 h-8 rounded-full text-orange-900 hover:bg-orange-300 text-sm"
            >
              <span className="flex items-center px-2 bg-primary h-5 rounded-full mr-2 text-white">
                <Arrow className="text-xl" />
              </span>
              Read success client's stories
            </Link>

            <h1 className="text-6xl leading-none font-serif mt-8 mb-4 block">
              {title}
            </h1>
            <p className="text-xl text-blue-900 leading-loose mb-5">
              {content}
            </p>

            <div className="inline-flex items-center space-x-5">
              <Button to={cta.url}>{cta.text}</Button>
            </div>
          </div>
          {/* <div className="relative z-20">
          </div> */}
          <HeroVideo />
          <HeroSvg className="absolute left-0 bottom-0 z-10" />
        </div>
      </section>
    </>
  )
}
