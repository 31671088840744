import React from "react"
import Button from "../components/button"
import ImageServices from "../components/imagesComponent/homeServices"

function ServiceBlock({
  title = "lorem",
  content = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque, pariatur!",
}) {
  return (
    <div className="my-2">
      <h3 className="text-primary text-4xl font-serif leading-none mb-2">
        {title}
      </h3>
      <p className="leading-loose">{content.substr(0, 120)}...</p>
    </div>
  )
}

export default function Service({ data = {} }) {
  const { serviceType } = data[0]

  console.log({ data })
  return (
    <section>
      <div className="container mx-auto px-5 grid md:grid-cols-2 gap-10 mt-5 md:mt-24 md:mb-24 mb-10 text-lg text-blue-900">
        <div className="h-48 md:h-full w-full rounded-lg overflow-hidden relative shadow-2xl">
          <div className="bg-primary absolute top-0 right-0 h-full w-full  overflow-hidden rounded-lg ">
            <ImageServices />
          </div>
        </div>
        <div>
          <div className="grid md:grid-cols-2 gap-5 mt-8">
            {serviceType.map(({ name, description }, index) => (
              <ServiceBlock
                key={`home-service-${index}`}
                title={name}
                content={description}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
