import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import CallToAction from "../components/callToAction"
import Hero from "../components/hero"
import HomeServices from "../components/homeServices"
import HomeTestimonials from "../components/testimonialsHome"

const IndexPage = ({ data }) => {
  const { barText } = data.takeshape.getTopBar
  const { header, services, testimonials, banner } = data.takeshape.getHomePage
  const footer = data.takeshape.getContactUsPage
  return (
    <Layout footer={footer} barText={barText}>
      <SEO title="Home" />
      <Hero data={header} />
      <HomeServices data={services} />
      <HomeTestimonials data={testimonials} />
      <CallToAction data={banner} />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query($locale: String) {
    takeshape {
      getHomePage(locale: $locale) {
        header {
          title
          content
          cta {
            text
            url
          }
        }
        services {
          serviceType {
            name
            description
          }
        }
        testimonials {
          title
          subtitle
          list {
            content
            author
            service
          }
          cta {
            text
            url
          }
        }
        banner {
          title
          subtitle
          cta {
            text
            url
          }
        }
      }
      getContactUsPage {
        phones {
          phone
        }
        facebook
        instagram
      }
      getTopBar(locale: $locale) {
        barText
      }
    }
  }
`
