import React from "react"
import Button from "../components/button"
import "./style.css"

export default function CallToAction({ data = {} }) {
  const { title, subtitle, cta } = data
  return (
    <div className="hero-bg bg-orange-100 w-full pb-3">
      <div className="container mx-auto px-5 py-8 text-center">
        <h2 className="text-primary text-5xl font-serif">{title}</h2>
        <p className="text-blue-900 mb-4">{subtitle}</p>
        <Button to={cta.url} tail="inline-flex">
          {cta.text}
        </Button>
      </div>
    </div>
  )
}
